import { EstadistiasUsuarios, EntidadesMenosAsociadas } from './../../../models/estadisticas';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EntidadService } from 'src/app/services/entity/entidad.service';
import { AuthService } from 'src/app/services/seguridad/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Entidad } from 'src/app/models/entidad';
import { OrderPipe } from 'ngx-order-pipe';
import { AsociacionService } from 'src/app/services/entity/asociacion.service';


@Component({
  selector: 'app-estadisticas-entidades',
  templateUrl: './estadisticas-entidades.component.html',
  styleUrls: ['./estadisticas-entidades.component.css']
})
export class EstadisticasEntidadesComponent implements OnInit {


  // DataTable --
  dataSource: MatTableDataSource<EntidadesMenosAsociadas>;
  displayedColumns = ['IdEntidad', 'entidad', 'asociaciones'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSourceAux: any;
  dataSourcePalabras: any;
  ROW_NUMBER: number;
  pageIndex = 1;
  pageSize = 10;
  filterValue = '';


  constructor(
    private Service: EntidadService,
    private authService: AuthService,
    private errorService: ErrorHandlerService,
    private asociacionService: AsociacionService,
    private router: Router) { }

  ngOnInit() {
    this.configurarPaginator();
    this.CargarDgvElements(this.pageIndex, this.pageSize, this.filterValue);
  }

  configurarPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
    this.paginator._intl.previousPageLabel = 'Anterior';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.firstPageLabel = 'Primero';
    this.paginator._intl.lastPageLabel = 'Último';
    
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    // Actualiza tus datos según la página actual
    this.CargarDgvElements(this.pageIndex + 1, this.pageSize, this.filterValue);
  }


  CargarDgvElements(pageIndex, pageSize, filterValue) {

    this.Service.getEntidadesMenosEvaluadas(pageIndex, pageSize, filterValue).subscribe(result => {

      this.dataSource = new MatTableDataSource<EntidadesMenosAsociadas>(result.data);
      //console.log(result.data)
      this.paginator.length = result.totalCount;

    }, (error) => {
      this.errorService.handleError(error);
    });

  }

  goToAsociciones() {

    const entidad = this.dataSource.filteredData[this.ROW_NUMBER];

    this.asociacionService.form.patchValue({
      IdEntidad1: entidad.IdEntidad,
      entidadSelecionada: entidad.entidad
    });
    this.redirectToAsociacion();
  }

  public redirectToAsociacion = () => {
    this.router.navigate(['Asociacion']);
  }


  applyFilter(filterValue: string) { 

    if (filterValue.length !== 0)
      if (filterValue.length < 3) {
        return;
      }
    this.filterValue = filterValue;
    this.CargarDgvElements(this.pageIndex, this.pageSize, this.filterValue);
  }

}
